import React from 'react'
import './footer.css';
const footer = () => {
  return (
  <footer className='footer'>
    copyright 2023. All right Reserved.
    </footer>
  )
}

export default footer