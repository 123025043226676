import Navbar from "./components/navbar/navbar";
import Intro from "./components/Intro/intro";
import Skills from "./components/Skills/skills";
import Footer from "./components/Footer/footer";
import Contact from "./components/Contact/contact";
import Works from "./components/Works/works";
import Home from './components/Home';
import Stock from './components/stock';
import { Route,Routes } from 'react-router-dom';

function App() {
  return (
    <div className="App">

    <Navbar/>
    <Routes>        
      <Route path="/Home"  element={<Home/>} />
      <Route path="/stock"  element={<Stock/>} />
    </Routes>
    

   
    <Intro/>
    <Skills/>
    <Works/>
    <Contact/>   
    
    <Footer/>
    </div>
    

    
  );
}

export default App;
