import React from 'react'
import './skills.css';

const skills = () => {
  return (
<section id = 'skills'>
    <span className='skillTitle'>what i do</span>
    <span className='skillDesc'> I am a skilled and passionate graphics designer, dedicated to creating captivating visual content that resonates with viewers. With a deep understanding of design principles and proficiency in software like Adobe Photoshop and Illustrator, I am well-equipped to produce compelling and aesthetically pleasing designs that effectively convey messages and enhance brand identities.





</span>
<div className='skillBars'>
<div className='skillBar'>
<div className='skillBarText'>
<h2>Graphics Design</h2>
<p>I am a passionate graphics designer who brings creativity and innovation to life through visual storytelling.</p>

</div>
</div>

<div className='skillBar'>
<div className='skillBarText'>
<h2>Website design</h2>
<p>
I'm a dedicated website designer with a flair for combining aesthetics and functionality to create engaging digital experiences.</p>

</div>
</div>

<div className='skillBar'>
<div className='skillBarText'>
<h2>TypeWriting</h2>
<p>I'm a skilled typist who takes pride in precision and speed, transforming words into documents with efficiency and accuracy.</p>


</div>
</div>
</div>

</section>
  )
}

export default skills