import React from 'react'
import por from '../../assets/winy.png'
import port from '../../assets/winy.png'
import './works.css';
const works = () => {
  return (
 
    <section id = 'works'>
<h2 className="worktitle">My Works </h2>
<span className='workdesk'>i take price for small attention detail</span>
      <div className='worksimgs'>
        <img src={por} width="500" height="500" alt="" className='worksimg'/>
        <img src={port} width="500" height="500" alt="" className='worksimg'/>
       < img src={por} width="500" height="500" alt="" className='worksimg'/>
        <img src={port} width="500" height="500" alt="" className='worksimg'/>
    
     </div>
        </section>
  );
}

export default works
