// rafce and enter
import React, { useState } from 'react';
import './navbar.css'
import logo from '../../assets/winylogo.png'

import contactImg from '../../assets/wyellow.jpg';
import menu from '../../assets/menu.png'
import {Link} from 'react-scroll';

const Navbar = () => {
  const[showMenu, setShowMenu] = useState(false);
  return (
 <nav className='navbar'  >
 <img src ={logo} alt="logo" className='logo'/>
 <div className="desktopmenu">
<Link activeClass="active" to ="intro" spy={true} smooth={true} offset={-100} duration={500} className="desktopMenuListItem">Home </Link>
<Link activeClass="active" to ="skills" spy={true} smooth={true} offset={-50} duration={500} className="desktopMenuListItem">About </Link>
<Link activeClass="active" to ="works" spy={true} smooth={true} offset={-50} duration={500} className="desktopMenuListItem">My works </Link>
<Link activeClass="active" to ="contact" spy={true} smooth={true} offset={-50} duration={500} className="desktopMenuListItem">Contact </Link>


 
 </div>
<button className = "desktopmenuBtn" onClick={()=>{
document.getElementById('contact').scrollIntoView(({behavior:'smooth'}));

}}>
   <img src = {contactImg} alt = "" width = "50" height ="50" className = "desktopMenuImg"/> contact me</button>


<img src ={menu} alt="Menu" className='mobMenu' onClick={()=>setShowMenu(!showMenu)}/>
 <div className="navMenu" style={{display: showMenu? 'flex':'none'}}>
<Link activeClass="active" to ="intro" spy={true} smooth={true} offset={-100} duration={500} className="ListItem"  onClick={()=>setShowMenu(false)}>Home </Link>
<Link activeClass="active" to ="skills" spy={true} smooth={true} offset={-50} duration={500} className="ListItem" onClick={()=>setShowMenu(false)}>About </Link>
<Link activeClass="active" to ="works" spy={true} smooth={true} offset={-50} duration={500} className="ListItem"  onClick={()=>setShowMenu(false)}>Portfolio </Link>
<Link activeClass="active" to ="contact" spy={true} smooth={true} offset={-50} duration={500} className="ListItem" onClick={()=>setShowMenu(false)}>Contact </Link>
 
 </div>
 </nav>
  )
}

export default Navbar