import React from 'react'
import './intro.css';
import bg from '../../assets/ww.png';
import { Link } from 'react-router-dom';

const Intro = () => {
  return (
   <section id = "intro">
<div className = "introContent">
              <span  className='hello'><br/><br/>    <br/> </span>
  <span className='introtext'>I am   <span className='introName'>Wintana Asfaw</span><br/>Graphics & Web Designer </span>

<p className='intropara'>I am a skilled and passionate graphics <br/> and web designer, dedicated to creat  <br/> ing captivating visual content that<br/> resonates with viewers. </p>

</div>

<img src={bg} alt= "profile" className="bg"/>
    </section>

  );
}

export default Intro