import React from 'react'
import './contact.css';
import tgicon from '../../assets/tgicon.png';
import fbicon from '../../assets/fbicon.png';
import tiktokicon from '../../assets/tiktokicon.png'
import instaicon from '../../assets/instaicon.png'

const contact = () => {
  return (
    <section id='contactpage'>
    <div id ="contact">
<h1 className='contactpagetitle'>Contact Me <br/> </h1>
<h3 className='adress'>Adress: Addis Ababa  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp;</h3>
<h3 className='email'>Email: Wintana.Asfaw@gmail.com</h3>
<h3 className='phone'>Phone Number: +251948000000 &nbsp; &nbsp; &nbsp;</h3>

<div className='links'>
<img src={tgicon} alt="tgicon" className='link' /> &nbsp; &nbsp; 
<img src={fbicon} alt="fbicon" className='link'/>  &nbsp; &nbsp;
<img src={tiktokicon} alt="tiktokicon" width="50"  className='link'/>  &nbsp; &nbsp;
<img src={instaicon} alt="instaicon" className='link'/>
    </div>
    </div>
    </section>
  )
}

export default contact